<mat-card-title class="title-margin" id="templatesView" fxLayout="column" fxLayout.gt-xs="row"
  fxLayoutAlign="start start" fxLayoutAlign.gt-xs="space-between center">
  <div class="breadcrumb">
    <a [routerLink]="['/templates']">Templates</a><span style="margin: 0 10px;">/</span>
    <a routerLink="/templates/details/edit/{{TID}}">{{ TID }}</a>
    <span> / Dashboards</span>
  </div>
  <div fxFlex="1 1 auto"></div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <button mat-raised-button color="primary" class="btn-new-device" style="margin-left: 6px;"
      (click)="navigateToCreateDashboard()" style="" id="newTemplateBtn">
      <mat-icon class="material-icons" aria-label="add">add</mat-icon>
      <span class="hide-mobile">New Dashboard</span>
    </button>
  </div>
</mat-card-title>
<ngx-datatable class="material block expandable striped" #templateDashboardListTable [columnMode]="'force'"
    [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [rows]="listOfDashboards" [scrollbarV]="true"
    [scrollbarH]="true">    
    <ngx-datatable-column name="Index" prop="Index" [minWidth]="50" [width]="70"></ngx-datatable-column> 
    <ngx-datatable-column name="Name" prop="Name" [minWidth]="100" [width]="250"></ngx-datatable-column>
    <ngx-datatable-column name="" prop="Id" [minWidth]="100" [width]="50" [sortable]="false">
      <ng-template ngx-datatable-cell-template active let-value="value" let-row="row" let-rowIndex="rowIndex">
        <a matTooltip="Navigate to Edit"
          (click)="navigateToEditDashboard(row.Id)"><mat-icon>
            edit
          </mat-icon></a>
          <a matTooltip="Delete Dashboard"
          (click)="deleteDashboardDialog(row.Id)" style="margin-left: 10px;"><mat-icon>
            delete
          </mat-icon></a>
      </ng-template>
    </ngx-datatable-column>
 </ngx-datatable>
