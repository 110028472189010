import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    template: `
    <div style="height: 100%" fxLayout="column" fxLayoutAlign="space-between start">
    <div>
      <h5 class="mt-0">Event Details</h5>

      <div class="row">
        <div class="column">Timestamp : {{ eventDetails.Timestamp }}</div>
      </div>
      <div class="row">
        <div class="column">Origin : {{ eventDetails.Origin }}</div>
      </div>
      <div class="row">
        <div class="column">Category : {{ eventDetails.Category }}</div>
      </div>
      <div class="row">
        <div class="column">Title : {{ eventDetails.Title }}</div>
      </div>
      <div class="row">
        <div class="column">Body : {{ eventDetails.Body }}</div>
      </div>
    </div>

    <div style="width: 100%" fxLayout="row" fxLayoutAlign="end end">
      <button mat-raised-button (click)="dialogRef.close()">CLOSE</button>
    </div>
  </div>`,
  })

  export class EventTableOverflowComponent {
    @Input() eventDetails : EventDetails;

    constructor(public dialogRef: MatDialogRef<EventTableOverflowComponent>) {}
  }  

  export interface EventDetails {
    Timestamp: string;
    Origin: string;
    Category: string;
    Title: string;
    Body: string;
  }