<!--breadcrumb-->
<mat-card-title class="title-margin" fxLayout="column" fxLayout.gt-xs="row">
  <div fxLayout="row wrap" fxLayoutAlign="start center" class="breadcrumb">
    <a [routerLink]="['/devices/all']">Devices </a>
    <span style="margin: 0 10px;">/</span>
    <a [routerLink]="['/devices', deviceId, 'details']" fxLayout="row"  fxLayoutAlign="start center">{{deviceId}}<span class="breadcumb-device-status" [style.background-color]="deviceStatusColor"></span></a>
    <span style="margin: 0 10px;">/</span>
    <span>Pulse Plot</span>
  </div>
  <div fxFlex="1 1 auto"></div>
  <app-reload-content (reload)="ngOnInit()"></app-reload-content>
</mat-card-title>


<mat-card-content>
  <!-- tabs  -->
  <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabSelectedChanged()">
    <mat-tab *ngFor="let tab of tabs">
      <div style="margin-top:15px;font-size:14px;">
        <span *ngIf="activePulses.length == 0">No pulse found for this device and pulse within the
          specified time
          range.</span>
      </div>
      <ng-template mat-tab-label>{{tab.label}}</ng-template>
      <mat-card-content class="block">

        <div fxLayout="column" fxLayout.gt-xs="row" style="width: fit-content ; margin:auto" fxLayoutGap="10px" fxLayoutAlign="center center"
        *ngIf="customDateSelected" style="margin-top: 15px">
          <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <label style="padding-bottom: 5px;">From</label>
            <mat-form-field>
              <input matInput [owlDateTime]="dt1" [(ngModel)]="tab._from" [owlDateTimeTrigger]="dt1" placeholder="Date Time">
              <owl-date-time #dt1></owl-date-time>
            </mat-form-field>
          </div>
          <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <label style="padding-bottom: 5px;">To</label>
            <!-- <input style="margin-top:3px;" [(ngModel)]="tab._to" ngui-datetime-picker /> -->
            <mat-form-field>
              <input matInput [owlDateTime]="dt2" [(ngModel)]="tab._to" [owlDateTimeTrigger]="dt2" placeholder="Date Time">
              <owl-date-time #dt2></owl-date-time>
            </mat-form-field>
          </div>
          <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center">
            <button type="button" mat-raised-button color="primary"
              (click)="searchHistory()" style="margin-bottom: 1.25em">Search</button>
          </div>
        </div>

        <div *ngIf="activePulses.length > 0">
          <chart [options]="pulseValues" class="block" (load)="saveChart($event.context)" style="width: 100%; height: 100%; display: block;"></chart>
        </div>

      </mat-card-content>
    </mat-tab>
  </mat-tab-group>
</mat-card-content>
