<!--breadcrumb-->
<mat-card-title class="title-margin" fxLayout="column" fxLayout.gt-xs="row">
  <div fxLayout="row wrap" fxLayoutAlign="start center" class="breadcrumb">
    <a [routerLink]="['/devices/all']">Devices </a>
    <span style="margin: 0 10px;">/</span>
    <a [routerLink]="['/devices', MID, 'details']" fxLayout="row"  fxLayoutAlign="start center">{{ MID }}<span class="breadcumb-device-status" [style.background-color]="deviceStatusColor"></span></a>
    <span style="margin: 0 10px;">/</span>
    <span>Permissions</span>
  </div>
  <div fxFlex="1 1 auto"></div>
  <app-reload-content (reload)="ngOnInit()"></app-reload-content>
</mat-card-title>

<mat-card-content style="padding-top:5px;">

  <ngx-datatable
    class="material block permission-table"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="50"
    [rows]="permissons"
    [scrollbarV]="true"
    [scrollbarH]="true"
  >
    <ngx-datatable-column
      name="User Id"
      prop="AccountId"
      
      [width]="175"
    ></ngx-datatable-column>
    <ngx-datatable-column
      name="Can Observe"
      prop="CanObserve"
      
      [width]="100"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span *ngIf="value"><i class="material-icons permission-green">check_circle</i></span>
        <span *ngIf="!value"><i class="material-icons permission-red">cancel</i></span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Can Control"
      prop="CanControl"
      
      [width]="100"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span *ngIf="value"><i class="material-icons permission-green">check_circle</i></span>
        <span *ngIf="!value"><i class="material-icons permission-red">cancel</i></span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Can Admin"
      prop="CanAdmin"
      
      [width]="100"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span *ngIf="value"><i class="material-icons permission-green">check_circle</i></span>
        <span *ngIf="!value"><i class="material-icons permission-red">cancel</i></span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</mat-card-content>

<ng-template #hdrTpl let-column="column">
  <strong>{{ column.name }} </strong>
</ng-template>
