<!--breadcrumb-->
<mat-card-title class="title-margin" fxLayout="column" fxLayout.gt-xs="row">
  <div fxLayout="row wrap" fxLayoutAlign="start center" class="breadcrumb">
    <a [routerLink]="['/devices/all']">Devices</a><span style="margin: 0 10px;">/</span>
    <a [routerLink]="['/devices', deviceId, 'details']" fxLayout="row" fxLayoutAlign="start center">{{deviceId}}<span
        class="breadcumb-device-status" [style.background-color]="deviceStatusColor"></span></a>
    <span style="margin: 0 10px;">/</span>
    <a [routerLink]="['/devices', deviceId, 'observations']">Observations</a>
    <span style="margin: 0 10px;">/</span>
    <span>{{observationId}} - {{observationName}}</span>
  </div>
  <div fxFlex="1 1 auto"></div>
  <app-reload-content (reload)="ngOnInit()"></app-reload-content>
</mat-card-title>

<mat-card-content>
  <!-- tabs  -->
  <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabSelectedChanged()"
    class="history-panel">
    <mat-tab *ngFor="let tab of tabs">
      <ng-template mat-tab-label>{{tab.name}}</ng-template>
      <div style="margin-top:15px;font-size:14px;">
        <span *ngIf="nodata && !tab.live"> No observations found for this device and observation within the specified
          time range.</span>
      </div>
      <!-- values table  -->
      <!-- <div fxLayout="row" fxLayoutWrap="wrap" *ngIf="tab.showDateRangeSelector" style="padding-top: 0px; padding-left : 10px;"> -->
      <div fxLayout="column" fxLayout.gt-xs="row" *ngIf="tab.showDateRangeSelector"
        style="width: fit-content; margin:auto" fxLayoutGap="10px" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
          <label style="padding-bottom: 5px;">From</label>
          <mat-form-field>
            <input matInput [owlDateTime]="dt1" [(ngModel)]="tab._from" [owlDateTimeTrigger]="dt1"
              placeholder="Date Time">
            <owl-date-time #dt1></owl-date-time>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
          <label style="padding-bottom: 5px;">To</label>
          <mat-form-field>
            <input matInput [owlDateTime]="dt2" [(ngModel)]="tab._to" [owlDateTimeTrigger]="dt2"
              placeholder="Date Time">
            <owl-date-time #dt2></owl-date-time>
          </mat-form-field>
        </div>
        <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center">
          <button type="button" mat-raised-button color="primary" (click)="onTabSelectedChanged()"
            style="margin-bottom: 1.25em">Search</button>
        </div>
      </div>

      <mat-card-content class="map-block">
        <div class="block" #mapElement attr.data-tabid="{{tab.id}}">
          <div #popupElement attr.data-tabid="{{tab.id}}"
            [ngClass]="{'popup': !nodata || tab.live, 'popupNoData': nodata && !tab.live}"></div>
        </div>
      </mat-card-content>
    </mat-tab>
  </mat-tab-group>
</mat-card-content>