import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'overflow-dialog',
  template: `
    <div style="height: 100%" fxLayout="column" fxLayoutAlign="space-between start">
      <div>
        <h5 class="mt-0">
          {{ details.MID }}, observation {{ details.ObservationName }} at
        {{ details.Timestamp }}
        </h5>

        <p>{{ details.CurrentValue }}</p>
      </div>
      <div style="width: 100%" fxLayout="row" fxLayoutAlign="end end">
          <button mat-raised-button (click)="dialogRef.close()">CLOSE</button>
      </div>
    </div>
  `
})
export class ObservationOverflowDialog {
  @Input() details: Details;

  constructor(public dialogRef: MatDialogRef<ObservationOverflowDialog>) {}
}

export interface Details {
  MID: string;
  ObservationName: string;
  Timestamp: string;
  CurrentValue: string;
}
