import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'overflow-dialog',
  template: `
    <div style="height: 100%" fxLayout="column" fxLayoutAlign="space-between start">
      <div>
        <h5 class="mt-0">
          Observation Received at
        {{ details.Timestamp }}
        </h5>

        <pre>{{ details.CurrentValue }}</pre>
      </div>
      <div style="width: 100%" fxLayout="row" fxLayoutAlign="end end">
          <button mat-raised-button (click)="dialogRef.close()">CLOSE</button>
      </div>
    </div>
  `
})
export class ObservationStatOverflowDialog {
  @Input() details: any;

  constructor(public dialogRef: MatDialogRef<ObservationStatOverflowDialog>) {
  }
}
