export class PolychromeColors {
    private static totalColorCount = 36;

    private static readonly X1 = "#5a5056";
    private static readonly X2 = "#e5e1e3";
    private static readonly X3 = "#f7222e";
    private static readonly X4 = "#ff00fc";
    private static readonly X5 = "#16ff32";
    private static readonly X6 = "#3283ff";
    private static readonly X7 = "#feaf16";
    private static readonly X8 = "#b00067";
    private static readonly X9 = "#1cffcf";
    private static readonly X10 = "#91ad1c";
    private static readonly X11 = "#2ed9ff";
    private static readonly X12 = "#dfa1fd";
    private static readonly X13 = "#ab0cff";
    private static readonly X14 = "#f49e9c";
    private static readonly X15 = "#325a9b";
    private static readonly X16 = "#c5441c";
    private static readonly X17 = "#1c8356";
    private static readonly X18 = "#85660c";
    private static readonly X19 = "#b10ca1";
    private static readonly X20 = "#fbe526";
    private static readonly X21 = "#1cbf4e";
    private static readonly X22 = "#fc0087";
    private static readonly X23 = "#fe1cbf";
    private static readonly X24 = "#f7e1a1";
    private static readonly X25 = "#f7e1a1";
    private static readonly X26 = "#782ab7";
    private static readonly X27 = "#abf500";
    private static readonly X28 = "#bdcdff";
    private static readonly X29 = "#812d1b";
    private static readonly X30 = "#b5efb5";
    private static readonly X31 = "#7ed7d1";
    private static readonly X32 = "#1c7e93";
    private static readonly X33 = "#d95ef7";
    private static readonly X34 = "#683a79";
    private static readonly X35 = "#66b1ff";
    private static readonly X36 = "#3a00fb";

    public static getColor(name: string): string | undefined {
        return PolychromeColors[name as keyof PolychromeColors];
    }

    public static getTotalColorCount(): number {
        return this.totalColorCount;
    }
}