import { Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'overflow-dialog',
  template: `
    <div style="height: 100%" fxLayout="column" fxLayoutAlign="space-between start">
      <div>
        <h5 class="mt-0">
          {{ modalInformation?.MID }}, observation {{ modalInformation?.ObservationName }} at
          {{ modalInformation?.Timestamp }}
        </h5>

        <p>{{ modalInformation?.CurrentValue }}</p>
      </div>
      <div style="width: 100%" fxLayout="row" fxLayoutAlign="end end">
          <button mat-raised-button (click)="close()">CLOSE</button>
      </div>
    </div>
  `
})
export class LatestOverflowDialog implements OnInit {
  @Input() details: Details;

  
  modalInformation: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Details,
    public dialogRef: MatDialogRef<LatestOverflowDialog>,
    private zone : NgZone) {
      setTimeout(() => { 
        this.zone.run(() => 
          this.setModalInfo()
        ) });
    }

    ngOnInit() {
    }

    setModalInfo() {
      this.modalInformation = this.data['observationOverflow'];
    }

    close() {
      this.zone.run(() => {
        this.dialogRef.close();
      });
      
    }
}

export interface Details {
  MID: string;
  ObservationName: string;
  Timestamp: string;
  CurrentValue: string;
}
