<!--breadcrumb-->
<mat-card-title class="title-margin" fxLayout="column" fxLayout.gt-xs="row">
  <div fxLayout="row wrap" fxLayoutAlign="start center" class="breadcrumb">
    <a [routerLink]="['/devices/all']">Devices</a>
    <span style="margin: 0 10px;">/</span>
    <a [routerLink]="['/devices', deviceMID, 'details']" fxLayout="row"  fxLayoutAlign="start center">{{ deviceMID }}<span class="breadcumb-device-status" [style.background-color]="deviceStatusColor"></span></a>
    <span style="margin: 0 10px;">/</span>
    <a [routerLink]="['/devices', deviceMID, 'observations']">Observations </a>
    <!-- <span>/ {{observationName}}</span> -->
    <span style="margin: 0 10px;">/</span>
    <span>Plot</span>
  </div>
  <div fxFlex="1 1 auto"></div>
  <app-reload-content (reload)="ngOnInit()"></app-reload-content>
</mat-card-title>

<mat-card-content>
  <!-- tabs  -->
  <mat-tab-group
    [(selectedIndex)]="selectedTabIndex"
    (selectedTabChange)="onTabSelectedChanged()"
  >
    <mat-tab *ngFor="let tab of tabs">
      <ng-template mat-tab-label>{{ tab.name }}</ng-template>

      <div style="margin-top:15px;font-size:14px;">
        <span *ngIf="nodata"
          >No observations found for this device and observation within the
          specified time range.</span
        >
      </div>

      <!-- values table  -->
      <mat-card-content>
        <div
        fxLayout="column" fxLayout.gt-xs="row"
          style="width: fit-content ; margin:auto"
          fxLayoutGap="10px"
          fxLayoutAlign="center center"
          *ngIf="tab.showDateRangeSelector"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
          >
            <label style="padding-bottom: 5px;">From</label>
            <mat-form-field>
              <input matInput [owlDateTime]="dt1" [(ngModel)]="tab._from" [owlDateTimeTrigger]="dt1" placeholder="Date Time">
              <owl-date-time #dt1></owl-date-time>
            </mat-form-field>
            <!-- <input
              style="margin-top:3px;"
              fxFlex.gt-sm="75%"
              fxFlex="50"
              [(ngModel)]="tab._from"
              ngui-datetime-picker
            /> -->
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
          >
            <label style="padding-bottom: 5px;">To</label>
            <mat-form-field>
              <input matInput [owlDateTime]="dt2" [(ngModel)]="tab._to" [owlDateTimeTrigger]="dt2" placeholder="Date Time">
              <owl-date-time #dt2></owl-date-time>
            </mat-form-field>
            <!-- <input
              style="margin-top:3px;"
              fxFlex.gt-sm="75%"
              fxFlex="50"
              [(ngModel)]="tab._to"
              ngui-datetime-picker
            /> -->
          </div>
          <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center">
            <button type="button" mat-raised-button color="primary" (click)="onTabSelectedChanged()" style="margin-bottom: 1.25em">Search</button>
          </div>
        </div>
        <!--Multiple charts-->
        <div fxLayout="row wrap">
          <div
            fxFlex.gt-md="{{ cardMd }}"
            fxFlex.gt-xs="{{ cardXs }}"
            fxFlex="100"
            *ngFor="let observation of selectedObservations"
          >
            <mat-card class="card">
              <mat-card-title class="card-title" style="padding-top: 1.6rem;">{{observation.Id}} - {{observation.Name}}</mat-card-title>
              <mat-card-content>
                <chart
                  [options]="tab.options"
                  class="{{ plotCssClass }}"
                  (load)="saveChart(tab, $event.context, observation.Id)"
                >
                </chart>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
  </mat-tab-group>
</mat-card-content>
