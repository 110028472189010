import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dump-overflow-dialog',
  template: `
    <div style="height: 100%" fxLayout="column" fxLayoutAlign="space-between start">
      <div>
        <h5 class="mt-0">
          Stream for {{ dump.MID }} at {{ dump.Timestamp }}
        </h5>

        <p>{{ dump.CurrentValue }}</p>
      </div>
      <div style="width: 100%" fxLayout="row" fxLayoutAlign="end end">
          <button mat-raised-button (click)="dialogRef.close()">CLOSE</button>
      </div>
    </div>
  `
})
export class DumpTableOverflowDialog {
  @Input() dump: DumpDetails;

  constructor(public dialogRef: MatDialogRef<DumpTableOverflowDialog>) {}
}

export interface DumpDetails {
  MID: string;
  Timestamp: string;
  CurrentValue: string;
}
